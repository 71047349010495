<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <div class="table-operator">
      <a-button v-action:add type="primary" icon="plus" @click="handleAdd">{{
          inspect_type == 1 ? '新增抽查' : '新增检查'
        }}
      </a-button>
      <SearchForm ref="SearchForm" @handleSubmit="handleSeachSubmit"/>
    </div>
    <div style="width: 100%;overflow-x: auto">
      <s-table
          ref="table"
          rowKey="inspect_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          tableLayout="auto"
          :scroll="{x:true}"
          :customRow="customRow"
          :rowClassName="setRowClassName"
      >
        <div slot="zq" slot-scope="text, item">
          {{ item.start_time }}~{{ item.end_time }}
        </div>
        <div slot="status" slot-scope="text, item">
          <a-tag color="green" v-if="text===0">启用</a-tag>
          <a-tag color="red" v-else>停用</a-tag>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:inspectVehicle @click="handleVehicle(item)">{{ inspect_type == 1 ? '抽查车辆' : '检查车辆' }}</a>
          <a v-action:delete @click="handleDelete(item)">删除</a>
        </div>
      </s-table>
    </div>
    <SaveForm :inspect_type="inspect_type" ref="SaveForm" @handleSubmit="handleRefresh"/>
  </a-card>
</template>

<script>
import * as Api from '@/api/inspect'
import {STable} from '@/components'
import SaveForm from './modules/SaveForm.vue'
import SearchForm from './modules/SearchForm.vue'

export default {
  name: 'Index',
  components: {
    STable,
    SaveForm,
    SearchForm,
  },
  props: {
    inspect_type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 查询参数
      queryParam: {
        inspect_type: this.inspect_type
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          width: 100,
          title: this.inspect_type == 1 ? '抽查编号' : '检查编号',
          dataIndex: 'inspect_number',
          align: 'center',
        },
        {
          width: 60,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              if (response.data.list.data.length > 0) {
                this.$nextTick(() => {
                  this.currentRow = response.data.list.data[0]
                  this.handleCheck()
                })
              } else {
                this.currentRow = {}
                this.handleCheck()
              }
              return response.data.list
            })
      },
      currentRow: {},
      customRow: record => {
        return {
          on: { // 事件
            click: (event) => {
              this.currentRow = record
              this.handleCheck()
              console.log(record)
            },       // 点击行
            dblclick: (event) => {
            },
            contextmenu: (event) => {
            },
            mouseenter: (event) => {
            },  // 鼠标移入行
            mouseleave: (event) => {
            }
          }
        }
      },

    }

  },
  created() {
    if (this.inspect_type == 0) {
      let elementToAdd = {
        title: '检查周期',
        dataIndex: 'zq',
        width: 200,
        align: 'center',
        scopedSlots: {customRender: 'zq'}
      };
      this.columns.splice(1, 0, elementToAdd);
    }
  },
  methods: {
    handleCheck() {
      this.$emit('check', this.currentRow)
    },
    setRowClassName: function (record) {
      return record === this.currentRow ? "clickRowStyl" : ''; //赋予点击行样式
    },
    /**
     * 查看确认列表
     */
    handleFixList(item) {
      this.$refs.FixListModel.queryParam.message_id = item.message_id
      this.$refs.FixListModel.open(item)
    },
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleVehicle(item) {
      this.currentRow = item
      this.handleCheck()
    },
    /**
     * 删除记录
     */
    handleDelete(item) {
      const app = this
      const modal = this.$confirm({
        title: '您确定要删除该记录吗?',
        content: '删除后不可恢复',
        onOk() {
          return Api.deleted({inspectId: item['inspect_id']})
              .then((result) => {
                app.$message.success(result.message, 1.5)
                app.handleRefresh()
              })
              .finally(result => {
                modal.destroy()
              })
        }
      })
    },

    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
